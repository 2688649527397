import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import { CONTACT_ADDRESS, CONTACT_EMAIL, CONTACT_PHONE } from "../helpers";

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact us"
      description="Whether you are planning to renovate an existing property or build a new home, get in touch with us for free consultation and sound building advice."
      image={"/contact-banner.jpg"}
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Contact us</h1>
        <div className="row">
          <div className="col-lg-9 mx-auto mb-4 mb-sm-5 text-center">
            <p className="lead">
              Have a question? We’d love to hear from you. Send us a message and
              we’ll respond as soon as possible.
            </p>
          </div>
        </div>
        <div className="row justify-content-around row-gap-4">
          <div className="mb-gutter col-sm-4 text-center">
            <i className="fas mb-3 fa-2x fa-phone"></i>
            <h5>Phone Number</h5>
            <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a>
          </div>
          <div className="mb-gutter col-sm-4 my-5 my-sm-0 text-center">
            <i className="fas mb-3 fa-2x fa-envelope"></i>
            <h5>Email</h5>
            <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
          </div>
          <div className="mb-gutter col-sm-4  text-center">
            <i className="fas mb-3 fa-2x fa-map-marker-alt"></i>
            <h5>Location</h5>
            {CONTACT_ADDRESS}
          </div>
          {/* <div className="mb-gutter col-sm-6  text-center">
                <i className="fas mb-3 fa-2x fa-clock"></i>
                <h5>Opening Hours</h5>
                <span>Weekdays: 06:00 – 17:00</span>
              </div> */}
        </div>
      </div>
    </section>
    <StaticImage
      src="../images/contact-banner.jpg"
      alt="Sierra Delta team"
      // aspectRatio={9 / 12}
    />

    <section id="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12026.20771102062!2d175.1028733!3d-41.1006357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9fd42d243fdf6f4f!2sSierra%20Delta%20Civil%20Limited!5e0!3m2!1sen!2snz!4v1654943239876!5m2!1sen!2snz"
        frameBorder={0}
        style={{ height: "100%", width: "100%", border: 0 }}
        allowFullScreen
        aria-hidden="false"
        title="Find us on Google maps"
      />
    </section>
  </Layout>
);

export default ContactPage;
